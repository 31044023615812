<template>
  <div class="body">
    <div class="head">
      <div class="left">老有所养</div>
      <div class="right">牵头单位：路桥区民政局</div>
    </div>
    <div class="btn">
      <div
        :class="item.index == indexA ? 'active' : ''"
        v-for="(item, index) in btnItem"
        :key="index"
        :style="item.index == 2 || item.index == 3 ? 'color:#DBDBDB' : ''"
        @click="handle(item.index)"
      >
        <el-tooltip
          placement="top"
          effect="light"
          v-if="index == 2 || index == 3"
          content="建设中"
          ><div>{{ item.text }}</div></el-tooltip
        >
        <div v-else>
          {{ item.text }}
        </div>
      </div>
    </div>
    <div
      v-if="indexA == 0"
      style="display: flex; flex-wrap: wrap; justify-content: space-between"
    >
      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>养老总览</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div class="cultureItem" v-for="(item, index) in cultural" :key="index">
          <div>
            <span>{{ item.text }}</span>
            <span class="span" v-if="item.btn" @click="btn(item.text, item.id)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn }}</span
            >
            <span
              class="span"
              v-if="item.btn2"
              @click="btn2(item.text, item.total, item.text, index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn2 }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size: 12px; margin-right: 3px">{{
              item.txt
            }}</span>
            <span
              style="font-size: 12px; color: #f96c70"
              v-if="item.trend.type == 0"
              >{{ item.trend.text }}</span
            >
            <span
              style="font-size: 12px; color: #30c566"
              v-if="item.trend.type == 1"
              >{{ item.trend.text }}</span
            >
          </div>
        </div>
        <!-- <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="4"
            small
            @current-change="change"
          >
          </el-pagination>
        </div> -->
      </div>

      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>居家养老</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div class="cultureItem" v-for="(item, index) in brand2" :key="index">
          <div>
            <span>{{ item.text }}</span>
            <span
              class="span"
              v-if="item.btn3"
              @click="btn3(item.text, item.total, item.text, index)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn3 }}</span
            >
            <span
              class="span"
              v-if="item.btn"
              @click="btn2(item.text, item.total, item.text, index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size: 12px; margin-right: 3px">{{
              item.txt
            }}</span>
            <span
              style="font-size: 12px; color: #f96c70"
              v-if="item.trend.type == 0"
              >{{ item.trend.text }}</span
            >
            <span
              style="font-size: 12px; color: #30c566"
              v-if="item.trend.type == 1"
              >{{ item.trend.text }}</span
            >
          </div>
        </div>

        <!-- <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="5"
            small
            @current-change="change2"
          >
          </el-pagination>
        </div> -->
      </div>

      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>养老机构</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in mechanism2"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span
              class="span"
              v-if="item.btn4"
              @click="btn4(item.text, item.total, item.text, index)"
              ><i class="iconfont icon-shujufenxi"></i
              >{{ " " + item.btn4 }}</span
            >
            <span
              class="span"
              v-if="item.btn"
              @click="btn2(item.text, item.total, item.text, index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size: 12px; margin-right: 3px">{{
              item.txt
            }}</span>
            <span
              style="font-size: 12px; color: #f96c70"
              v-if="item.trend.type == 0"
              >{{ item.trend.text }}</span
            >
            <span
              style="font-size: 12px; color: #30c566"
              v-if="item.trend.type == 1"
              >{{ item.trend.text }}</span
            >
          </div>
        </div>

        <!-- <div style="display: flex; justify-content: center">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="3"
            :total="4"
            small
            @current-change="change3"
          >
          </el-pagination>
        </div> -->
      </div>

      <div class="culture">
        <div class="title">
          <div class="titleDiv">
            <span></span>
            <div>社区养老</div>
          </div>
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
            </div>
            <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
          </el-tooltip>
        </div>
        <div
          class="cultureItem"
          v-for="(item, index) in community"
          :key="index"
        >
          <div>
            <span>{{ item.text }}</span>
            <span
              class="span"
              v-if="item.btn"
              @click="btn5(item.text, item.total, item.text, index)"
              ><i class="iconfont icon-17yanjing2"></i
              >{{ " " + item.btn }}</span
            >
          </div>
          <div>
            {{ item.num }}<span class="company">{{ item.company }}</span>
          </div>
          <div v-if="item.trend">
            <span style="font-size: 12px; margin-right: 3px">{{
              item.txt
            }}</span>
            <span
              style="font-size: 12px; color: #f96c70"
              v-if="item.trend.type == 0"
              >{{ item.trend.text }}</span
            >
            <span
              style="font-size: 12px; color: #30c566"
              v-if="item.trend.type == 1"
              >{{ item.trend.text }}</span
            >
          </div>
        </div>
      </div>

      <el-dialog
        :title="title"
        :visible.sync="dialogMedal"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div style="color: #000; font-size: 14px">{{ oText }}</div>
        <div style="color: #1492ff; font-size: 12px">
          <span>{{ totalTitle }}</span>
        </div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart" style="width: 100%; height: 500px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible2"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="main4" style="width: 320px; height: 303px"></div>
        <div ref="main5" style="width: 320px; height: 303px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible3"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="main6" style="width: 100%; height: 500px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible4"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart7" style="width: 100%; height: 500px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible5"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div class="dialogVisible5_box">
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">临海市长庚健康养老服务中心</div>
            <div class="dialogVisible5_t2">13958563688</div>
            <div class="dialogVisible5_t3" style="color: green">正常</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">台州路桥浙江君同物业有限公司</div>
            <div class="dialogVisible5_t2"></div>
            <div class="dialogVisible5_t3" style="color: red">停止服务</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">台州路桥杭州市健康社区服务中心</div>
            <div class="dialogVisible5_t2"></div>
            <div class="dialogVisible5_t3" style="color: red">停止服务</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">台州路桥一家亲服务有限公司</div>
            <div class="dialogVisible5_t2"></div>
            <div class="dialogVisible5_t3" style="color: red">停止服务</div>
          </div>
          <div class="dialogVisible5_title">
            <div class="dialogVisible5_t1">昆山乐惠居养老服务中心</div>
            <div class="dialogVisible5_t2">13776081829</div>
            <div class="dialogVisible5_t3" style="color: green">正常</div>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible6"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart8" style="width: 100%; height: 500px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible8"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart9" style="width: 100%; height: 500px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible7"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="70%"
      >
        <div ref="chart10" style="width: 100%; height: 500px"></div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible9"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="80%"
      >
        <div style="width:100%">
          <div style="width:100%;display:flex;justify-content: space-between;flex-wrap:wrap">
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
              <div style="font-size:16px;font-weight:bold;margin-left:15px;">服务人员数量</div>
              <div style="font-size:14px;font-weight:bold;margin-left:80px;color:#158fff">50人</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">总回访次数</div>
              <div style="font-size:14px;font-weight:bold;margin-left:80px;color:#158fff">106人</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">总满意度</div>
              <div style="font-size:14px;font-weight:bold;margin-left:80px;color:#158fff">100%</div>
            </div>
            <!-- <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">社会体育组织</div>
              <div style="font-size:14px;font-weight:bold;margin-left:30px;color:#158fff">35个</div>
            </div> -->
          </div>

        </div>
      </el-dialog>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible10"
        :append-to-body="true"
        :close-on-click-modal="false"
        width="80%"
      >
        <div style="width:100%">
          <div style="width:100%;display:flex;justify-content: space-between;flex-wrap:wrap">
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
              <div style="font-size:16px;font-weight:bold;margin-left:15px;">呼叫总次数</div>
              <div style="font-size:14px;font-weight:bold;margin-left:80px;color:#158fff">0次</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">千人床位数</div>
              <div style="font-size:14px;font-weight:bold;margin-left:80px;color:#158fff">31个</div>
            </div>
            <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">入住率</div>
              <div style="font-size:14px;font-weight:bold;margin-left:80px;color:#158fff">3.3%</div>
            </div>
            <!-- <div style="display:flex;background:#dcefff;width:48%;height:70px;text-align:center;line-height:70px;margin-top:25px">
               <div style="font-size:16px;font-weight:bold;margin-left:15px;">社会体育组织</div>
              <div style="font-size:14px;font-weight:bold;margin-left:30px;color:#158fff">35个</div>
            </div> -->
          </div>

        </div>
      </el-dialog>



    </div>

    <div v-if="indexA == 1" class="culture" style="width: 100%; height: 590px">
      <div class="title">
        <div class="titleDiv">
          <span></span>
          <div>老有所养地图</div>
        </div>
        <el-tooltip placement="top" effect="light">
          <div slot="content">
            {{ time1 }}<br />{{ time2 }}<br />更新频率：每季度
          </div>
          <img src="../assets/闹钟.png" @mouseenter="mouseenter" />
        </el-tooltip>
      </div>
      <div>
        <div id="map" style="width: 100%; height: 1000px"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { LabelsData } from "./map";

export default {
  components: {},
  data() {
    return {
      dialogMedal: false,
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      dialogVisible8: false,
      dialogVisible9: false,
      dialogVisible10: false,
      btnItem: [
        { text: "核心业务", index: 0 },
        { text: "资源分布", index: 1 },
        { text: "预警监测", index: 2 },
        { text: "制度创新", index: 3 },
      ],
      indexA: 0,
      cultural: [
        {
          id: 1,
          text: "60岁以上人口",
          num: "99878",
          company: "人",
          txt: "当年累计99878人 同比",
          trend: { type: 1, text: "↑ 10%" },
          btn: "看趋势",
        },

        {
          id: 3,
          text: "养老机构数量",
          num: "14",
          company: "家",
          txt: "当年累计14家  同比",
          trend: { type: 1, text: "↑ 0%" },
          btn: "看详情",
        },
        {
          id: 4,
          text: "社区养老社会网点",
          num: "235",
          company: "个",
          txt: "当年累计235个  同比",
          trend: { type: 1, text: "↑ 12.56%" },
          btn: "看详情",
        },
      ],
      // cultural2: [
      //    {id:1, text: "60岁以上人口",num: "99878",company: "人",txt:"当年累计99878人 同比",trend:{type:1,text:"↑ 10%"}, btn: "看趋势"},
      //   {id:2, text: "居家养老 上门服务次数",num: "50888",company: "次",txt:"当年累计50888次 同比",trend:{type:1,text:"↑ 32%"}, btn2: "看详情",total:"总次数50888次" },
      //   {id:3, text: "养老机构数量",num: "14",company: "家",txt:"当年累计14家  同比",trend:{type:1,text:"↑ 0%"}, btn: "看详情" },
      // ],
      // brand: [
      //   {
      //     text: "服务覆盖老人数",
      //     num: "2394",
      //     company: "人",
      //     txt: "当年累计2261人  同比",
      //     trend: { type: 1, text: "↑ 10%" },
      //     btn3: "看详情",
      //     total: "总个数2394人",
      //   },
      //   {
      //     text: "服务商",
      //     num: "5",
      //     company: "家",
      //     txt: "当年累计4家  同比",
      //     trend: { type: 1, text: "↑ 0%" },
      //     btn3: "看详情",
      //     total: "总个数5家",
      //   },
      //   {
      //     text: "服务人员数量",
      //     num: "50",
      //     company: "人",
      //     txt: "当年累计50人  同比",
      //     trend: { type: 1, text: "↑ 15%" },
      //     btn: "看详情",
      //     total: "总人数50人",
      //   },
      //   {
      //     text: "总回访次数",
      //     num: "106",
      //     company: "人",
      //     txt: "当年累计106人  同比",
      //     trend: { type: 1, text: "↑ 25%" },
      //     btn: "看详情",
      //     total: "总人数106人",
      //   },
      //   {
      //     text: "总满意度",
      //     num: "100",
      //     company: "%",
      //     txt: "当年累计100% 同比",
      //     trend: { type: 1, text: "↑ 0%" },
      //     btn: "看详情",
      //     total: "满意度100%",
      //   },
      // ],
      brand2: [
        {
          text: "服务覆盖老人数",
          num: "2394",
          company: "人",
          txt: "当年累计2261人  同比",
          trend: { type: 1, text: "↑ 10%" },
          btn3: "看详情",
          total: "总个数2394人",
        },
        {
          text: "服务商",
          num: "5",
          company: "家",
          txt: "当年累计4家  同比",
          trend: { type: 1, text: "↑ 0%" },
          btn3: "看详情",
          total: "总个数5家",
        },
        {
          text: "服务人员及满意度",
          num: "156",
          company: "人",
          txt: "当年累计156人  同比",
          trend: { type: 1, text: "↑ 50%" },
          btn3: "看详情",
          total: "总人数156人",
        },
      ],
      // mechanism: [
      //   {
      //     text: "上门服务次数",
      //     num: "14283",
      //     company: "次",
      //     txt: "当年累计14283次  同比",
      //     trend: { type: 1, text: "↑ 32%" },
      //     btn4: "看详情",
      //     total: "总次数14283次",
      //   },
      //   {
      //     text: "呼叫总次数",
      //     num: "0",
      //     company: "次",
      //     txt: "当年累计0次  同比",
      //     trend: { type: 1, text: "↑ 0%" },
      //     btn: "看详情",
      //     total: "总次数0次",
      //   },
      //   {
      //     text: "千人床位数",
      //     num: "31",
      //     company: "个",
      //     txt: "当年累计31个  同比",
      //     trend: { type: 1, text: "↑ 5%" },
      //     btn: "看详情",
      //     total: "总数31个",
      //   },
      //   {
      //     text: "入住率",
      //     num: "3.3",
      //     company: "%",
      //     txt: "当年累计3.3%  同比",
      //     trend: { type: 1, text: "↑ 0.12%" },
      //     btn: "看详情",
      //     total: "总数3.3%",
      //   },
      // ],
      mechanism2: [
        {
          text: "上门服务次数",
          num: "14283",
          company: "次",
          txt: "当年累计14283次  同比",
          trend: { type: 1, text: "↑ 32%" },
          btn4: "看详情",
          total: "总次数14283次",
        },
        {
          text: "床位入住率及呼叫数",
          num: "98%",
          // company: "次",
          txt: "  同比",
          trend: { type: 1, text: "↑ 0%" },
          btn4: "看详情",
          total: "总次数0次",
        },
      ],
      community: [
        {
          text: "居家养老服务中心",
          num: "12",
          company: "个",
          txt: "当年累计12个   同比",
          trend: { type: 1, text: "↑ 0%" },
          btn: "看详情",
          total: "总数12个",
        },
        {
          text: "居家养老服务照料中心",
          num: "224",
          company: "个",
          txt: "当年累计224个  同比",
          trend: { type: 1, text: "↑ 12.56%" },
          btn: "看详情",
          total: "总数224个",
        },
      ],
      show: true,
      nowTime: null,
      time1: "",
      time2: "",
      title: "",
      totalTitle: "",
      oText: "",
    };
  },
  created() {
    const isZzd = JSON.stringify(navigator.userAgent).indexOf("TaurusApp") > -1;
    if (isZzd) {
      console.log(1);
      this.listenResize();
    }
     else {
      window.location.href =
        "https://dgov-integrate.jinhua.gov.cn:5443/szjh-gov-pc/#/404";
    }
  },
  mounted() {
    // this.init();
    // this.init2();
    // this.init3();
    // this.init4();
    // this.init5();
    // this.init6();
  },
  watch: {
    dialogVisible(e) {
      if (e) {
        setTimeout(() => {
          this.init();
        }, 1);
      }
    },
    dialogVisible2(e) {
      if (e) {
        setTimeout(() => {
          this.init4();
          this.init5();
        }, 1);
      }
    },
    dialogVisible3(e) {
      if (e) {
        setTimeout(() => {
          this.init6();
        }, 1);
      }
    },
    dialogVisible4(e) {
      if (e) {
        setTimeout(() => {
          this.init7();
        }, 1);
      }
    },
    dialogVisible6(e) {
      if (e) {
        setTimeout(() => {
          this.init8();
        }, 1);
      }
    },
    dialogVisible7(e) {
      if (e) {
        setTimeout(() => {
          this.init10();
        }, 1);
      }
    },
    dialogVisible8(e) {
      if (e) {
        setTimeout(() => {
          this.init9();
        }, 1);
      }
    },
  },
  methods: {
    listenResize() {
      setTimeout(() => {
        window.onresize = () => {
          this.init();
          this.init4();
          this.init5();
          this.init6();
          this.init7();
          this.init8();
          this.init9();
          this.init10();
        };
      }, 0);
    },
    mouseenter() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      // this.time1 = "数据范围：" + yy + "年" + "1月-" + mm + "月";
      this.time1 = "数据范围：截止2022年1月";
      this.time2 = "更新时间：" + yy + "年" + mm + "月" + dd + "日";
    },
    nowSize(val, initWidth = 1554) {
      let nowClientWidth = document.documentElement.clientWidth;
      return val * (nowClientWidth / initWidth);
    },
    init() {
      this.chart = echarts.init(this.$refs.chart);
      let option = {
        tooltip: {
          trigger: "axis",
        },
        color: ["#1492FF"],
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#000",
              },
            },
          },
        },
        grid: {
          left: "0",
          right: "6%",
          top: "2%",
          x: 0,
          x2: 10,
          y2: 20,
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [
            "100岁以上",
            "90-99岁",
            "80-89岁",
            "70-79岁",
            "65-69岁",
            "60-64岁",
          ],
        },
        series: [
          {
            type: "bar",
            data: [786, 6000, 7000, 26000, 22000, 28000],
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init2() {
      this.chart = echarts.init(this.$refs.main2);
      // var myChart2 = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        color: ["#1492FF"],
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
            },
            axisLine: {
              lineStyle: {
                width: 28,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 100,
              },
            ],
            detail: {
              width: 50,
              fontSize: 20,
              offsetCenter: [0, "0%"],
              valueAnimation: true,
              color: "#1492FF",
              formatter: "上门服务",
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    init3() {
      this.chart = echarts.init(this.$refs.main3);
      // var myChart3 = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "15%",
        },
        xAxis: {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
          boundaryGap: false,
          data: [
            "2021-01",
            "2021-02",
            "2021-03",
            "2021-04",
            "2021-05",
            "2021-06",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [1248, 1158, 3430, 4308, 5499, 4644],
            type: "line",
            areaStyle: {},
          },
        ],
      };

      this.chart.setOption(option);
    },
    init4() {
      this.chart = echarts.init(this.$refs.main4);
      // var myChart4 = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {},
        series: [
          {
            name: "机构数量",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 2,
                name: "公办",
              },
              {
                value: 6,
                name: "民办",
              },
              {
                value: 1,
                name: "敬老院",
              },
            ],
          },
        ],
      };

      this.chart.setOption(option);
    },
    init5() {
      this.chart = echarts.init(this.$refs.main5);
      // var myChart5 = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "item",
        },
        legend: {},
        series: [
          {
            name: "机构数量",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 11,
                name: "医生",
              },
              {
                value: 32,
                name: "护士",
              },
              {
                value: 286,
                name: "护理人员",
              },
            ],
          },
        ],
      };

      this.chart.setOption(option);
    },
    init6() {
      this.chart = echarts.init(this.$refs.main6);
      // var myChart6 = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["居家养老服务中心", "照料中心", "示范型养老服务中心"],
        },

        calculable: true,
        xAxis: [
          {
            type: "category",
            data: [
              "路桥街道",
              "路南街道",
              "路北街道",
              "桐屿街道",
              "螺洋街道",
              "新桥镇",
              "横街镇",
              "峰江街道",
              "蓬街镇",
              "金清镇",
            ],
             axisLabel:{
        		interval: 0,
        		rotate: 20,             
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "居家养老服务中心",
            type: "bar",
            data: [17, 20, 14, 20, 13, 19, 18, 23, 41, 50],
            markPoint: {},
          },
          {
            name: "示范型养老服务中心",
            type: "bar",
            data: [1, 1, 1, 2, 1, 1, 1, 1, 1, 1],
            markPoint: {},
          },
          {
            name: "照料中心",
            type: "bar",
            data: [16, 19, 13, 18, 12, 18, 17, 22, 40, 49],
            markPoint: {},
          },
        ],
      };

      this.chart.setOption(option);
    },
    init7() {
      this.chart = echarts.init(this.$refs.chart7);
      let option = {
        tooltip: {
          trigger: "axis",
        },
        color: ["#1492FF"],
        itemStyle: {
          normal: {
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#000",
              },
            },
          },
        },
        grid: {
          left: "0",
          right: "6%",
          top: "2%",
          x: 0,
          x2: 10,
          y2: 20,
          containLabel: true,
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: [
            "峰江街道",
            "横街镇",
            "金清镇",
            "路北街道",
            "路南街道",
            "路桥街道",
            "螺洋街道",
            "蓬街镇",
            "桐屿街道",
            "新桥镇",
          ],
        },
        series: [
          {
            type: "bar",
            data: [123, 159, 714, 4, 221, 412, 143, 367, 38, 213],
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init8() {
      this.chart = echarts.init(this.$refs.chart8);
      let option = {
         tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
           data: [
            "峰江街道",
            "横街镇",
            "金清镇",
            "路北街道",
            "路南街道",
            "路桥街道",
            "螺洋街道",
            "蓬街镇",
            "桐屿街道",
            "新桥镇",
          ],
           axisLabel:{
        		interval: 0,
        		rotate: 20,             
            },
          
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [619, 1120, 6000, 24, 741, 1708, 773, 2066, 187, 1045],
            type: "bar",
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init9() {
      this.chart = echarts.init(this.$refs.chart9);
      let option = {
         tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
           data: [
            "路桥街道",
            "路南街道",
            "路北街道",
            "桐屿街道",
            "螺洋街道",
            "新桥镇",
            "横街镇",
            "峰江街道",
            "蓬街镇",
            "金清镇",
          ],
           axisLabel:{
        		interval: 0,
        		rotate: 20,             
            },
          
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [17, 20, 14, 20, 13, 20, 18, 23, 41, 50],
            type: "bar",
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    init10() {
      this.chart = echarts.init(this.$refs.chart10);
      let option = {
         tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
           data: [
            "路桥街道",
            "路南街道",
            "路北街道",
            "桐屿街道",
            "螺洋街道",
            "新桥镇",
            "横街镇",
            "峰江街道",
            "蓬街镇",
            "金清镇",
          ],
           axisLabel:{
        		interval: 0,
        		rotate: 20,             
            },
          
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [1, 1, 1, 2, 1, 2, 1, 1, 1, 1],
            type: "bar",
            color:'#299bfd'
          },
        ],
      };
      this.chart.setOption(option);
      this.chart.resize();
    },
    initMap() {
      var map = new AMap.Map("map", {
        viewMode: "3D",
        zoom: 13,
        center: [121.374146, 28.568495],
        resizeEnable: true,
      });
      var layer = new AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        // 开启标注避让，默认为开启，v1.4.15 新增属性
        collision: true,
        // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
        animation: true,
      });

      map.add(layer);

      var markers = [];

      for (var i = 0; i < LabelsData.length; i++) {
        var curData = LabelsData[i];
        // curData.extData = {
        //   index: i+10,
        // };

        var labelMarker = new AMap.LabelMarker(curData);

        markers.push(labelMarker);

        layer.add(labelMarker);
      }

      map.setFitView();
    },
    handle(e) {
      if (e == 1) {
        this.show = false;
        setTimeout(() => {
          this.initMap();
        }, 1);
      } else if (e == 0) {
        this.show = true;
      } else {
        console.log(e);
      }
      if (e == 0 || e == 1) {
        this.indexA = e;
      }
    },
    change(e) {
      if (e == 1) {
        this.cultural2 = this.cultural.slice(0, 3);
      } else if (e == 2) {
        this.cultural2 = this.cultural.slice(3, 6);
      }
    },
    change2(e) {
      if (e == 1) {
        this.brand2 = this.brand.slice(0, 3);
      } else if (e == 2) {
        this.brand2 = this.brand.slice(3, 6);
      }
    },
    change3(e) {
      if (e == 1) {
        this.mechanism2 = this.mechanism.slice(0, 3);
      } else if (e == 2) {
        this.mechanism2 = this.mechanism.slice(3, 6);
      }
    },
    btn(e, i) {
      this.title = e;
      console.log(e);
      console.log(i);
      if (i == 1) {
        this.dialogVisible = true;
      } else if (i == 3) {
        this.dialogVisible2 = true;
      } else if (i == 4) {
        this.dialogVisible3 = true;
      }
    },
    btn2(e, v, t, i) {
      this.dialogMedal = true;
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
    },
    btn3(e, v, t, i) {
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
      console.log(e);
      console.log(i);
      if (i == 0) {
        this.dialogVisible4 = true;
      } else if (i == 1) {
        this.dialogVisible5 = true;
      } else if (i == 2) {
        this.dialogVisible9 = true;
      }
    },
    btn4(e, v, t, i) {
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
      console.log(e);
      console.log(i);
       if (i == 0) {
        this.dialogVisible6 = true;
      } else if (i == 1) {
        this.dialogVisible10 = true;
      }
    },
    btn5(e, v, t, i) {
      this.title = e;
      this.totalTitle = v;
      this.oText = t;
       if (i == 0) {
        this.dialogVisible7 = true;
      } else if (i == 1) {
        this.dialogVisible8 = true;
      }
      console.log(e);
      console.log(i);

      
    },
  },
};
</script>

<style lang="less">
.body {
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 4px 12px 2px rgba(42, 82, 117, 0.06);
  border-radius: 4px;
  padding: 20px;
  .head {
    margin: 0 auto;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(
      270deg,
      rgba(20, 146, 255, 0.81) 1%,
      #0085f8 100%
    );
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    .left {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #ffffff;
      line-height: 32px;
      font-weight: 500;
      font-size: 24px;
    }
    .right {
      font-size: 20px;
      opacity: 0.9;
      font-family: PingFangSC-Regular;
      font-size: 20px;
      color: #ffffff;
      text-align: right;
      line-height: 28px;
      font-weight: 400;
    }
  }
  .btn:hover {
    cursor: pointer;
  }
  .btn {
    width: 100%;
    height: 40px;
    background: #f7f7f7;
    border-radius: 6px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    div {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-family: PingFangSC-Medium;
    }
    .active {
      background: #ffffff;
      border: 1px solid #1492ff;
      border-radius: 6px;
      color: #1492ff;
      font-weight: 600;
    }
  }
  .culture {
    width: 49%;
    height: 474px;
    border: 1px solid #9dd2ff;
    border-radius: 4px;
    margin-bottom: 16px;
    overflow: hidden;
    .title {
      width: 100%;
      height: 40px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 18.6px;
      .titleDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width: 4px;
          height: 20px;
          background: #1492ff;
          border-radius: 2px;
          margin-left: 12px;
        }
        div {
          font-size: 20px;
          margin-left: 8px;
          font-family: Helvetica;
          font-size: 20px;
          color: #000000;
          letter-spacing: 0;
          font-weight: 400;
        }
      }

      img {
        width: 19.948px;
      }
    }
    .common {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .left {
        height: 199.485px;
        background-color: #f7f7f7;
        margin-left: 19.948px;
        padding: 0 19.948px;
        .up {
          width: 100%;
          height: 49.879px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1.008px solid #ccc;
          img {
            width: 19.948px;
            height: 19.948px;
          }
          div {
            font-size: 19.964px;
            margin-left: 4.991px;
          }
        }
        .down {
          width: 100%;
          height: 139.639px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .index {
            display: flex;
            flex-direction: column;
            align-items: center;
            .num {
              margin-bottom: 19.948px;
              color: #6ab2fb;
              font-size: 29.931px;
            }
            .item {
              font-size: 17.965px;
            }
          }
        }
      }
    }
    .cultureItem {
      width: calc(100% - 32px);
      height: 118px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 2px 8px 0px rgba(51, 51, 51, 0.05);
      border-radius: 8px;
      margin: 11px 16px 11px;
      padding: 16px;
      span:nth-child(1) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #000000;
        letter-spacing: 0;
        line-height: 26px;
        font-weight: 500;
      }
      .span {
        width: 78px;
        height: 26px;
        float: right;
        background: #ffffff;
        border: 0.5px solid #1492ff;
        border-radius: 4px;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        line-height: 26px;
        .iconfont {
          color: #1492ff;
        }
      }
      .span:hover {
        cursor: pointer;
      }
      div:nth-child(2) {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #1492ff;
        letter-spacing: 0;
        line-height: 28px;
        font-weight: 500;
        display: flex;
        align-items: center;
        .company {
          margin-left: 4px;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: #666666;
          letter-spacing: 0;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    .el-pagination {
      button {
        display: none;
      }
      .number {
        color: #59aaf5;
        background: #edf4ff;
      }
      .active {
        color: #ffffff;
        background: #1492ff;
      }
    }
  }
  .art {
    margin-top: 9.982px;
    border-radius: 7.982px;
    overflow: hidden;
    border: 0.992px solid #1191ff;
    .title {
      width: 100%;
      height: 47.895px;
      background-color: #e7f4fe;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 29.931px 0 9.982px;
      div {
        font-size: 15.965px;
        font-weight: 900;
        padding-left: 19.948px;
        border-left: 4.991px solid #1191ff;
      }
      img {
        width: 19.948px;
      }
    }
    .commonn {
      width: 100%;
      padding: 21.948px;
      display: flex;
      .data {
        width: 488.746px;
        height: 219.434px;
        background-color: #f7f7f7;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .dataItem {
          display: flex;
          justify-content: space-between;
          padding: 0 42.889px 0 19.948px;
          .left {
            display: flex;
            justify-content: center;
            align-items: center;
            .spot {
              width: 9.982px;
              height: 9.982px;
              border-radius: 50%;
              background-color: #058cfe;
              margin-right: 14.957px;
            }
            .item {
              font-size: 15.965px;
            }
          }
          .num {
            color: #058cfe;
            font-size: 15.965px;
          }
        }
      }
    }
  }
}
.el-dialog__wrapper {
  width: 100%;
  .el-dialog__header {
    .el-dialog__title {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #000000;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
      border-left: 4px solid #1492ff !important;
      border-radius: 2px;
      padding-left: 8px;
    }
    .el-dialog__headerbtn {
      width: 26px;
      height: 26px;
      // background: #1492ff;
      border-radius: 50%;
      i {
        color: #000;
      }
    }
  }
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px;
}
.v-modal {
  width: 100% !important;
}
.el-dialog__body {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.dialogVisible5_title {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
}
.dialogVisible5_box {
  width: 100%;
}
.dialogVisible5_t1 {
  flex: 2;
}
.dialogVisible5_t2 {
  flex: 1;
}
.dialogVisible5_t3 {
  flex: 2;
}
</style>